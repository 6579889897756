function pageHeader() {
	const $header = $('.page-header');
	const $nav = $('.page-nav');

	$(window).on('scroll', function() {
		if (this.scrollY > 80) {
			$header.addClass('page-header--sticky');
			$nav.addClass('page-nav--sticky');
		} else {
			$header.removeClass('page-header--sticky');
			$nav.removeClass('body-nav-sticky');
		}
	});

	$('.page-header-logo').on('click', function(e) {
		e.preventDefault();


		$('html, body').animate({
			scrollTop : 0
		}, 1000);
	});
}

export { pageHeader }