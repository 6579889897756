const $slider = $('.team-member-slider');

function markName() {
	const $current = $slider.find('.slick-current');
	$slider.find('.slick-slide').removeClass('active-slide active-slide-prev active-slide-next');
	$current.prev().addClass('active-slide-prev');
	$current.next().addClass('active-slide-next');
	$current.addClass('active-slide');
}

function teamPersonSlider() {
	$slider.slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true,
		arrows : true,
		cssEase: 'ease-in-out'
	});
	$slider.on('beforeChange', function() {
		markName();
	});
	$slider.on('afterChange', function() {
		markName();
	});

	setTimeout(function() {
		markName();
	}, 500);
}


export { teamPersonSlider }