//https://stanko.github.io/get-scrollbar-width-in-javascript/
function getScrollbarWidth() {
	return window.innerWidth - document.documentElement.clientWidth
}

//zaznacza aktualna pozycje na stronie w glownej nawigacji
function navToggle() {
	const $pageHeader = $('.page-header');
	const $navToggle = $('.page-nav-toggle');
	const $nav = $('.page-nav');
	const $body = $('body');

	//dodaje dla nawigacji prawy margines by zniwelowac
	//mini skok spodowodany pojawieniem sie/ukryciem scrolla
	$pageHeader.css('padding-right', getScrollbarWidth());
	$nav.css('padding-right', getScrollbarWidth());

	//pokazywanie, ukrywanie navigacji
	$navToggle.on('click', function() {
		$navToggle.addClass('page-nav-toggle-active');
		$nav.addClass('page-nav-show');

		$nav.on('transitionend.navOn', function() {
			$body.css({
				'margin-right' : getScrollbarWidth()
			});
			$pageHeader.css('padding-right', getScrollbarWidth());
			$body.addClass('body-page-nav-show');
			$nav.off('transitionend.navOn')
		});

	});

	$('.page-nav-close, .page-nav-list a').on('click', function() {
		//poprawa domyslnych scroli - by strona nie skakala
		$nav.removeClass('page-nav-show');
		$body.removeClass('body-page-nav-show');
		$body.css({
			'margin-right' : 0
		});
		$nav.on('transitionend.nav', function() {
			$nav.off('transitionend.nav')
		});
		$navToggle.removeClass('page-nav-toggle-active');

	});
}

function markNavElement() {
	let $sections = $('.non-exist-element');

	$('.page-nav-list a').each(function() {
		const href = $(this).attr('href');
		$sections = $sections.add($(href));
	});

	function markLink() {
	    let currentSection = -1;
		$('.page-nav-list li').removeClass('active');
		$sections.each(function() {
			if (window.scrollY + window.innerHeight / 2 > $(this).offset().top) {
				currentSection = $sections.index($(this));
			}
		});

		if (currentSection !== -1) {
			$('.page-nav-list li:eq('+currentSection+')').addClass('active');
		}
	}

	markLink();

	$(window).on('scroll', function() {
		markLink();
	});
}

function navElementsClick() {
	const $nav = $('.page-nav');
	const $navToggle = $('.page-nav-toggle');

	$('.page-nav-list a').on('click', function(e) {
		e.preventDefault();

		$nav.removeClass('page-nav-show');
		$navToggle.removeClass('page-nav-toggle-active');

		const $sectionTarget = $($(this).attr('href'));

		let fixScroll = parseInt($sectionTarget.data('scroll'), 10);
		if (isNaN(fixScroll)) {
			fixScroll = 0;
		}

		let pos = $sectionTarget.offset().top + fixScroll;
		if ($sectionTarget.attr('id') === 'start') {
			pos = 0;
		}

		$('html, body').animate({
			scrollTop : pos
		}, 1500);
	});
}

function pageNav() {
	navToggle();

	navElementsClick();
	markNavElement();
}

export { pageNav }