import {pageHeader} from "./_header";
import {pageNav} from "./_nav";
import {teamPersonSlider} from "./_team-person-slider";
import {scrollAnim} from "./_scroll";
import {whatWeDo} from "./_what-we-do";


$(function() {
	pageHeader();
	pageNav();
	teamPersonSlider();
	scrollAnim();
	whatWeDo();
});