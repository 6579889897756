//zmiana kolejności boksów na rozdzielczościach
//innym rozwiazaniem moze byc zastosowanie grida, ale
//przy testach wychodzilo nędzne wsparcie na mobilkach

const media = window.matchMedia('(max-width:760px)');
const $section = $('.section-what-we-do');
const $box1 = $('.section-what-we-do-box--one');
const $box2 = $('.section-what-we-do-box--two');
const $box3 = $('.section-what-we-do-box--three');
const $box4 = $('.section-what-we-do-box--four');
const $box5 = $('.section-what-we-do-box--five');

const $row1 = $('.section-what-we-do-row--one');
const $row2 = $('.section-what-we-do-row--two');

function changeOrder(media) {
	if (media.matches) {
		console.log($box3)
		$row1.append($box3);
		$row1.append($box5);
		$row2.append($box2);

		$row2.append($box4);
		$row2.append($box1);

		$box1.on('animationend', function() {
			$section.addClass('animate-end');
		});
	} else {
		$row1.append($box1);
		$row1.append($box2);
		$row1.append($box3);
		$row2.append($box4);
		$row2.append($box5);

		$box5.on('animationend', function() {
			$section.addClass('animate-end');
		});
	}
}

function whatWeDo() {
	changeOrder(media);

	media.addListener(function(media) {
		changeOrder(media);
	})
}

export {whatWeDo}