//sprawdzam pozycje elementu na ekranie
function isScrolledIntoView($elem) {
	const docViewTop = $(window).scrollTop();
	const docViewBottom = docViewTop + $(window).height() - 150;
	const elemTop = $elem.offset().top;
	return (elemTop < docViewBottom);
}

function scrollAnim() {
	$(window).on('scroll', function() {
		$('.section, .page-footer').each(function() {
			if (isScrolledIntoView($(this))) {
				$(this).addClass('animate');
			}
		})
	})
}

export {scrollAnim}